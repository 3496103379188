import React from 'react';
import { PropsWithClassName } from '@Components/helper';
interface Props extends PropsWithClassName {
  xs?: number;
  xsOffset?: number;
  sm?: number;
  smOffset?: number;
  md?: number;
  mdOffset?: number;
  lg?: number;
  lgOffset?: number;
  children?: React.ReactNode;
}
const Col = (props: Props): React.ReactElement => {
  const {
    xs,
    xsOffset,
    sm,
    smOffset,
    md,
    mdOffset,
    lg,
    lgOffset,
    children,
    className
  } = props;
  const renderClassNameString = (): string => {
    let classNameString = '';
    if (className !== undefined) {
      classNameString += className;
    }
    if (xs !== undefined) {
      classNameString += ` col-xs-${xs}`;
    } else {
      classNameString += ' col-xs';
    }
    if (xsOffset !== undefined) {
      classNameString += ` col-xs-offset-${xsOffset}`;
    }
    if (sm !== undefined) {
      classNameString += ` col-sm-${sm}`;
    } else {
      classNameString += ' col-sm';
    }
    if (smOffset !== undefined) {
      classNameString += ` col-sm-offset-${smOffset}`;
    }
    if (md !== undefined) {
      classNameString += ` col-md-${md}`;
    } else {
      classNameString += ' col-md';
    }
    if (mdOffset !== undefined) {
      classNameString += ` col-md-offset-${mdOffset}`;
    }
    if (lg !== undefined) {
      classNameString += ` col-lg-${lg}`;
    } else {
      classNameString += ' col-lg';
    }
    if (lgOffset !== undefined) {
      classNameString += ` col-lg-offset-${lgOffset}`;
    }
    return classNameString;
  };
  return <div className={renderClassNameString()} data-testid={props.dataTestId ?? 'Col-root'} data-sentry-component="Col" data-sentry-source-file="Col.tsx">
      {children}
    </div>;
};
export { Col };