'use client';

import React, { PropsWithChildren, useEffect, useState } from 'react';
import ThemeModel from '@Api/models/ThemeModel';
import Col from '@Components/atoms/Col';
import Container from '@Components/atoms/Container';
import Headline from '@Components/atoms/Headline';
import Row from '@Components/atoms/Row';
import { useGlobalContext } from '@Components/context/GlobalContext';
import { PropsWithClassName } from '@Components/helper';
import { usePrevious } from '@Components/helper/usePrevious';
import { IconWrap, Root } from './Main.styles';
interface Props extends PropsWithClassName {
  icon?: React.ReactNode;
  children?: React.ReactNode;
  headline?: string | null;
  isFluid?: boolean;
}
const Main: React.FC<PropsWithChildren<Props>> = (props: PropsWithChildren<Props>): React.ReactElement => {
  const {
    dataTestId,
    icon,
    headline,
    isFluid = false
  } = props;
  const {
    currentTheme
  } = useGlobalContext();
  const previousCurrentTheme = usePrevious(currentTheme);
  const [oldTheme, setOldTheme] = useState<ThemeModel | undefined>(currentTheme);
  const [newTheme, setNewTheme] = useState<ThemeModel | undefined>(undefined);
  const [opacity, setOpacity] = useState<number>(0);
  useEffect(() => {
    if (currentTheme?.colors === oldTheme?.colors) {
      return;
    }
    setOldTheme(previousCurrentTheme);
    setNewTheme(currentTheme);
    setOpacity(1);
    setTimeout(() => {
      setOldTheme(currentTheme);
      setNewTheme(undefined);
      setOpacity(0);
    }, 250);
  }, [currentTheme]);
  const renderContent = () => {
    let content = props.children;
    if (headline) {
      content = <>
          <Row>
            <Col xs={12} sm={12} md={12} lg={12}>
              <Headline importance={2}>{headline}</Headline>
            </Col>
          </Row>
          {props.children}
        </>;
    }
    if (!isFluid) {
      return <Container>{content}</Container>;
    }
    return content;
  };
  return <Root data-testid={dataTestId ?? 'main-root'} $oldTheme={oldTheme} $newTheme={newTheme} $opacity={opacity} $isFluid={isFluid} data-sentry-element="Root" data-sentry-component="Main" data-sentry-source-file="Main.tsx">
      {icon && <IconWrap> {icon}</IconWrap>}
      {renderContent()}
    </Root>;
};
export default Main;